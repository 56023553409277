import React, { useState, useEffect, useRef } from 'react';
import { Play, Pause, ArrowLeft, Loader } from 'lucide-react';
import FilterSelector from './FilterSelector';
import './ResultsPage.css';

const DEFAULT_FILTER = { id: 'uncharted', streamThreshold: 0 };
const MIN_SONGS_BEFORE_SHOW = 0; // Minimum songs needed before showing results
const ENGLISH_ISRC_COUNTRIES = ['GB', 'US', 'CA', 'AU', 'NZ']; //, 'QZ'];

const BACKEND = "http://54.165.219.229:8001"

const ResultsPage = ({ songs = [], onBack }) => {
  const [currentlyPlaying, setCurrentlyPlaying] = useState(null);
  const [audio, setAudio] = useState(null);
  const [loadingSong, setLoadingSong] = useState(null);
  const [loadingSimilar, setLoadingSimilar] = useState(null);
  const [displayedSongs, setDisplayedSongs] = useState([]);
  const [referenceSong, setReferenceSong] = useState(null);
  const [selectedFilter, setSelectedFilter] = useState(DEFAULT_FILTER);
  const [filteredSongs, setFilteredSongs] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const audioCache = useRef(new Map());
  const resultsListRef = useRef(null);
  const abortControllerRef = useRef(null);
  const [englishOnly, setEnglishOnly] = useState(false);
  const [isError, setIsError] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
    
  const cleanupRef = useRef({ isUnmounted: false });
    
  // Function to filter songs based on stream count
    const filterSongs = (songs, filter) => {
        if (!Array.isArray(songs) || !filter?.id) {
          return [];
        }

        const thresholds = {
          'uncharted': 0,
          'upcoming': 500000,
          'popular': 5000000
        };
        
        const threshold = thresholds[filter.id] || thresholds.popular;
        
        let filtered = songs;
        
        // Apply English filter
        if (englishOnly) {
          filtered = filtered.filter(song => {
            const countryCode = song.id?.slice(0, 2);
              console.log(song, countryCode);
            return ENGLISH_ISRC_COUNTRIES.includes(countryCode);
          });
        }
        
        // Apply stream threshold filter
        if (filter.id === 'uncharted') {
          filtered = filtered.filter(song => song.stream_count < thresholds.upcoming);
        } else {
          filtered = filtered.filter(song => song.stream_count >= threshold);
        }
        
        return filtered;
      };

    useEffect(() => {
        return () => {
          cleanupRef.current.isUnmounted = true;
          resetAudioStates();
          if (abortControllerRef.current) {
            abortControllerRef.current.abort();
          }
        };
      }, []);
    
  // Update filtered songs when either all songs or filter changes
  useEffect(() => {
    const filtered = filterSongs(songs, selectedFilter);
    setFilteredSongs(filtered);
    if (filtered.length > 0 && !referenceSong) {
      setReferenceSong(filtered[0]);
    }
    
    // Show loading until we have minimum number of songs
    if (filtered.length >= MIN_SONGS_BEFORE_SHOW) {
      setIsLoading(false);
    }
  }, [songs, selectedFilter, referenceSong, englishOnly]);

  // Update displayed songs when filtered songs change
  useEffect(() => {
    setDisplayedSongs(filteredSongs);
  }, [filteredSongs]);

  // Pre-load all audio files when songs are received
  useEffect(() => {
    displayedSongs.forEach(song => {
      if (song?.preview_url && !audioCache.current.has(song.id)) {
        const audioElement = new Audio();
        audioElement.src = song.preview_url;
        audioElement.preload = 'auto';
        audioCache.current.set(song.id, audioElement);
      }
    });
  }, [displayedSongs]);

  // Cleanup function for when component unmounts
  useEffect(() => {
    return () => {
      // Cleanup audio
      if (audio) {
        audio.pause();
        audio.src = '';
      }
      audioCache.current.forEach(cachedAudio => {
        cachedAudio.pause();
        cachedAudio.src = '';
      });
      audioCache.current.clear();
      
      // Cancel any ongoing fetch requests
      if (abortControllerRef.current) {
        abortControllerRef.current.abort();
      }
    };
  }, []);
    
    const goBack = () => {
      // Immediately trigger parent callback
      onBack();
      
      // Cancel ongoing requests first
      if (abortControllerRef.current) {
        abortControllerRef.current.abort();
      }

      // Batch state updates
      requestAnimationFrame(() => {
        if (audio) audio.pause();
        setDisplayedSongs([]);
        setFilteredSongs([]);
        setReferenceSong(null);
        setIsError(false);
        setErrorMessage('');
        
        // Defer audio cleanup
        setTimeout(resetAudioStates, 0);
      });
    };
    
  /*const goBack = () => {
    setTimeout(() => {
        onBack();
    }, 0);
      
    if (audio) audio.pause();
      
    // Cancel any ongoing requests
    if (abortControllerRef.current) {
      abortControllerRef.current.abort();
    }
    
    // Cleanup audio
    if (audio) {
      audio.pause();
      audio.src = '';
    }
    audioCache.current.forEach(cachedAudio => {
      cachedAudio.pause();
      cachedAudio.src = '';
    });
    audioCache.current.clear();
  };*/

  const resetAudioStates = () => {
    if (audio) {
      audio.pause();
      audio.src = '';
    }
    setAudio(null);
    setCurrentlyPlaying(null);
    audioCache.current.forEach(cachedAudio => {
      cachedAudio.pause();
      cachedAudio.src = '';
    });
    audioCache.current.clear();
  };

    const handleShowSimilar = async (song, e) => {
        if (!song?.id || loadingSimilar) return;
        
        e.stopPropagation();
        setLoadingSimilar(song.id);
        setIsError(false);
        setIsLoading(true); // Start loading state
        
        // Cancel previous request
        if (abortControllerRef.current) {
          abortControllerRef.current.abort();
        }
        abortControllerRef.current = new AbortController();
        
        try {
          const response = await fetch(`${BACKEND}/similar`, {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({
              song_id: song.id,
              isrc: song.isrc || song.id,
              k: 20,
              start_year: 1950,
              end_year: 2024,
              is_upload: false,
              stream_threshold: 1000000
            }),
            signal: abortControllerRef.current.signal
          });

          if (!response.ok) {
            const errorText = await response.text();
            throw new Error(errorText);
          }

          if (cleanupRef.current.isUnmounted) return;

          const reader = response.body.getReader();
          const decoder = new TextDecoder();
          const songs = [];
          let hasStartedDisplaying = false;

          while (true) {
            const { done, value } = await reader.read();
            if (done) break;
            if (cleanupRef.current.isUnmounted) {
              reader.cancel();
              return;
            }

            const chunk = decoder.decode(value);
            const lines = chunk.split('\n').filter(Boolean);

            for (const line of lines) {
              try {
                const newSong = JSON.parse(line);
                songs.push(newSong);

                // As soon as we get the first song, show the results
                if (!hasStartedDisplaying && songs.length > 0) {
                  hasStartedDisplaying = true;
                  setIsLoading(false);
                  resetAudioStates();
                  setReferenceSong(song);
                  const filtered = filterSongs(songs, selectedFilter);
                  setFilteredSongs(filtered);
                  setDisplayedSongs(filtered);
                } else {
                  // Update the lists with new songs
                  const filtered = filterSongs(songs, selectedFilter);
                  setFilteredSongs(filtered);
                  setDisplayedSongs(filtered);
                }
              } catch (error) {
                console.error('Error parsing song JSON:', error);
              }
            }
          }

          window.scrollTo({ top: 0, behavior: 'smooth' });
        } catch (error) {
          if (!cleanupRef.current.isUnmounted) {
            if (error.name === 'AbortError') {
              console.log('Fetch aborted');
            } else {
              console.error('Failed to fetch similar songs:', error);
              setIsError(true);
              setErrorMessage('Failed to fetch similar songs. Please try again.');
            }
          }
        } finally {
          if (!cleanupRef.current.isUnmounted) {
            setLoadingSimilar(null);
          }
        }
    };
    
  const handlePlayPreview = async (song) => {
    if (!song?.preview_url) return;

    if (currentlyPlaying?.id === song.id) {
      audio.pause();
      setCurrentlyPlaying(null);
      setAudio(null);
    } else {
      if (audio) {
        audio.pause();
      }
      setLoadingSong(song.id);
      
      const newAudio = new Audio(song.preview_url);
      
      try {
        await new Promise((resolve, reject) => {
          newAudio.addEventListener('canplaythrough', resolve, { once: true });
          newAudio.addEventListener('error', reject, { once: true });
          newAudio.load();
        });

        await newAudio.play();
        setAudio(newAudio);
        setCurrentlyPlaying(song);
      } catch (error) {
        console.error('Error loading audio:', error);
      } finally {
        setLoadingSong(null);
      }

      newAudio.addEventListener('ended', () => {
        setCurrentlyPlaying(null);
        setAudio(null);
      });
    }
  };

  const getButtonIcon = (song) => {
    if (loadingSong === song?.id) {
      return <Loader className="play-icon animate-spin" />;
    }
    if (currentlyPlaying?.id === song?.id) {
      return <Pause className="play-icon" />;
    }
    return <Play className="play-icon" />;
  };

  if (isLoading) {
    return (
      <div className="results-page">
        <div className="back-button" onClick={goBack}>
          <ArrowLeft className="back-icon" />
          Back
        </div>
        <div className="loading-container">
          <Loader className="loading-icon animate-spin" />
          <div className="loading-text">Finding similar songs...</div>
        </div>
      </div>
    );
  }
    
    if (isError) {
        return (
          <div className="results-page">
            <div className="back-button" onClick={goBack}>
              <ArrowLeft className="back-icon" />
              Back
            </div>
            <div className="error-container">
              <div className="error-message">{errorMessage}</div>
              <button onClick={() => setIsError(false)} className="retry-button">
                Retry
              </button>
            </div>
          </div>
        );
      }

  return (
    <div className="results-page">
      <div className="back-button" onClick={goBack}>
        <ArrowLeft className="back-icon" />
        Back
      </div>

          <div className="results-section">
                  <div className="filter-container">
                    <FilterSelector
                      onChange={setSelectedFilter}
                      style={{
                        transform: 'translate(-50%, -50%) scale(1)',
                        transformOrigin: 'center center'
                      }}
                    />
                  </div>
                  <div className="results-header">
                    <div>Similar Songs ({filteredSongs.length} {selectedFilter?.id || 'matching'} tracks)</div>
                    <div
                      className="english-filter"
                      onClick={() => setEnglishOnly(!englishOnly)}
                      style={{
                        display: 'flex',
                        alignItems: 'center',
                        gap: '8px',
                        cursor: 'pointer',
                        padding: '8px 12px',
                        background: 'rgba(52, 40, 30, 0.9)',
                        border: '1px solid rgba(255, 240, 220, 0.2)',
                        borderRadius: '4px',
                        marginLeft: '16px'
                      }}
                    >
                      <div
                        className="toggle-switch"
                        style={{
                          width: '32px',
                          height: '16px',
                          backgroundColor: englishOnly ? 'rgba(216, 201, 155, 0.8)' : 'rgba(216, 201, 155, 0.2)',
                          borderRadius: '8px',
                          position: 'relative',
                          transition: 'background-color 0.2s'
                        }}
                      >
                        <div
                          className="toggle-knob"
                          style={{
                            width: '14px',
                            height: '14px',
                            backgroundColor: 'rgb(255, 240, 220)',
                            borderRadius: '50%',
                            position: 'absolute',
                            top: '1px',
                            left: englishOnly ? '17px' : '1px',
                            transition: 'left 0.2s'
                          }}
                        />
                      </div>
                      English Only
                    </div>
                  </div>
        <div className="results-list">
          {displayedSongs.map((song) => (
            <div
              key={song.id}
              className="result-item"
              onClick={() => handlePlayPreview(song)}
            >
              <div className="result-artwork">
                <img
                  src={song.artwork_url}
                  alt={song.title}
                  className="result-image"
                />
                <button
                  className={`play-button ${
                    currentlyPlaying?.id === song.id ? 'playing' : ''
                  } ${loadingSong === song.id ? 'loading' : ''}`}
                  disabled={!song.preview_url || loadingSong === song.id}
                >
                  {getButtonIcon(song)}
                </button>
              </div>
              <div className="result-info">
                <div className="result-title">{song.title}</div>
                <div className="result-artist">{song.artist}</div>
                <div className="result-album">{song.album} • {song.year}</div>
              </div>
              <div className="result-actions">
                {/*}<button
                  className={`similar-button ${loadingSimilar === song.id ? 'loading' : ''}`}
                  onClick={(e) => handleShowSimilar(song, e)}
                  disabled={loadingSimilar === song.id}
                >
                  {loadingSimilar === song.id ? (
                    <Loader className="similar-icon animate-spin" />
                  ) : (
                    'Show Similar'
                  )}
                </button>*/}
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default ResultsPage;
