import { useState, useEffect } from 'react';

export const useResponsiveLayout = (minAspectRatio, maxAspectRatio) => {
  const MIDDLE_RATIO = 2500 / 1625; // Your image's natural aspect ratio
  const BASE_HEIGHT = 1625; // Base image height
  const BASE_WIDTH = 2500;  // Base image width
  
  const [layout, setLayout] = useState({
    windowWidth: window.innerWidth,
    windowHeight: window.innerHeight,
    factor: 0,
    baseScale: 1,
    isPortraitScaling: true
  });

  useEffect(() => {
    const calculateLayout = () => {
      const windowWidth = window.innerWidth;
      const windowHeight = window.innerHeight;
      const currentAspectRatio = windowWidth / windowHeight;
      
      let factor;
      let isPortraitScaling;
      let baseScale;
        
        console.log(currentAspectRatio);

      // Calculate scale based on window size relative to base image size
      if (currentAspectRatio <= MIDDLE_RATIO) {
        // Window is narrower than natural ratio - scale based on width
        baseScale = windowWidth / BASE_WIDTH;
        isPortraitScaling = true;
        factor = Math.pow((currentAspectRatio - minAspectRatio) / (MIDDLE_RATIO - minAspectRatio), 0.5);
      } else {
        // Window is wider than natural ratio - scale based on height
        baseScale = windowHeight / BASE_HEIGHT;
        isPortraitScaling = false;
        factor = Math.pow((currentAspectRatio - MIDDLE_RATIO) / (maxAspectRatio - MIDDLE_RATIO), 0.5);
      }

      // Ensure factor stays within bounds
      //factor = Math.max(0, Math.min(1, factor));

      return {
        windowWidth,
        windowHeight,
        factor,
        baseScale,
        isPortraitScaling
      };
    };

    const handleResize = () => {
      const newLayout = calculateLayout();
      setLayout(newLayout);
    };

    handleResize();
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, [minAspectRatio, maxAspectRatio]);

  const interpolatePosition = (portraitPos, naturalPos, landscapePos) => {
    if (!layout.factor && layout.factor !== 0) return {
      ...naturalPos,
      scale: layout.baseScale * (naturalPos.scale || 1)
    };
    
    if (layout.isPortraitScaling) {
      const scale = portraitPos.scale !== undefined && naturalPos.scale !== undefined
        ? portraitPos.scale + (naturalPos.scale - portraitPos.scale) * layout.factor
        : layout.baseScale;
        
      return {
        x: portraitPos.x + (naturalPos.x - portraitPos.x) * layout.factor,
        y: portraitPos.y + (naturalPos.y - portraitPos.y) * layout.factor,
        scale: scale * layout.baseScale
      };
    } else {
      const scale = naturalPos.scale !== undefined && landscapePos.scale !== undefined
        ? naturalPos.scale + (landscapePos.scale - naturalPos.scale) * layout.factor
        : layout.baseScale;
        
      return {
        x: naturalPos.x + (landscapePos.x - naturalPos.x) * layout.factor,
        y: naturalPos.y + (landscapePos.y - naturalPos.y) * layout.factor,
        scale: scale * layout.baseScale
      };
    }
  };

  return {
    interpolatePosition,
    windowWidth: layout.windowWidth,
    windowHeight: layout.windowHeight,
    factor: layout.factor,
    baseScale: layout.baseScale,
    isPortraitScaling: layout.isPortraitScaling
  };
};
