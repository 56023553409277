import React, { useState } from 'react';

const PasswordPage = ({ onPasswordSuccess }) => {
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  const betaPassword = 'BETA2024';

  const handleSubmit = (e) => {
    e.preventDefault();
    if (password === betaPassword) {
      onPasswordSuccess();
    } else {
      setError('Access Denied');
      setPassword('');
    }
  };

  return (
    <div className="password-page">
      <div className="vhs-overlay"></div>
      <div className="noise"></div>
      <div className="deck-container">
        <div className="deck-frame">
          <div className="deck-display">
            <div className="vu-meters">
              <div className="vu-meter">
                <div className="vu-needle"></div>
                <div className="vu-scale"></div>
              </div>
              <div className="vu-meter">
                <div className="vu-needle"></div>
                <div className="vu-scale"></div>
              </div>
            </div>
            <div className="display-panel">
              <div className="led-display">BETA ACCESS REQUIRED</div>
              <form onSubmit={handleSubmit} className="password-form">
                <input
                  type="password"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  placeholder="••••••••"
                  className="password-input"
                  autoFocus
                />
                {error && <div className="password-error">{error}</div>}
                <button type="submit" className="password-submit">
                  AUTHENTICATE
                </button>
              </form>
            </div>
          </div>
          <div className="deck-controls">
            <div className="control-group">
              <div className="control-knob"></div>
              <div className="control-knob"></div>
              <div className="control-knob"></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PasswordPage;
