import React, { useState, useEffect, useRef } from 'react';
import CassettePage from './CassettePage';
import ResultsPage from './ResultsPage';
import PasswordPage from './PasswordPage';
import './App.css';

const MIN_SONGS_REQUIRED = 5;
const TRANSITION_DELAY = 500;
const VIDEO_TIMEOUT = 10000; // 10 seconds max for video playback

function App() {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [currentPage, setCurrentPage] = useState('cassette');
  const [backgroundImage, setBackgroundImage] = useState(null);
  const [songs, setSongs] = useState([]);
  const [isTransitioning, setIsTransitioning] = useState(false);
  const [isError, setIsError] = useState(false);
  const videoRef = useRef(null);
  const containerRef = useRef(null);
  const hasReceivedSongsRef = useRef(false);
  const videoTimeoutRef = useRef(null);
  const abortControllerRef = useRef(null);

  // Function to cancel any ongoing requests
  const cancelOngoingRequests = () => {
    if (abortControllerRef.current) {
      abortControllerRef.current.abort();
      abortControllerRef.current = new AbortController();
    }
  };

  // Function to reset all states
  const resetStates = () => {
    setSongs([]);
    setIsError(false);
    hasReceivedSongsRef.current = false;
    if (videoTimeoutRef.current) {
      clearTimeout(videoTimeoutRef.current);
    }
  };

  const startVideoTransition = () => {
    resetStates();
    setIsTransitioning(true);
    
    // Create new abort controller for this session
    cancelOngoingRequests();
    abortControllerRef.current = new AbortController();
    
    // Transition to video
    setTimeout(() => {
      setCurrentPage('video');
      setIsTransitioning(false);
      
      // Set timeout to transition away from video if we don't get enough songs
      /*videoTimeoutRef.current = setTimeout(() => {
        if (currentPage === 'video' && !hasReceivedSongsRef.current) {
          handleError();
        }
      }, VIDEO_TIMEOUT);*/
    }, TRANSITION_DELAY);
  };

  // Handle error cases
  const handleError = () => {
    setIsError(true);
    cancelOngoingRequests();
    resetStates();
    setCurrentPage('cassette');
  };

  // Handle going back to cassette page
  const handleBack = () => {
    setIsTransitioning(true);
    cancelOngoingRequests();
    resetStates();
    
    setTimeout(() => {
      setCurrentPage('cassette');
      setIsTransitioning(false);
    }, TRANSITION_DELAY);
  };

  // Load background images
  useEffect(() => {
    const img = new Image();
    const timeoutId = setTimeout(() => {
      img.src = currentPage === 'cassette' ? '/background.jpg' : '/background2.jpg';
      img.onload = () => setBackgroundImage(img);
    }, TRANSITION_DELAY);

    return () => clearTimeout(timeoutId);
  }, [currentPage]);

  // Set video playback rate
  useEffect(() => {
    if (videoRef.current) {
      videoRef.current.playbackRate = 0.4;
    }
  }, [currentPage]);

  // Cleanup on unmount
  useEffect(() => {
    return () => {
      cancelOngoingRequests();
      if (videoTimeoutRef.current) {
        clearTimeout(videoTimeoutRef.current);
      }
    };
  }, []);

  // Handler for updating songs as they stream in
  const handleSongsUpdate = (newSongs) => {
    if (!Array.isArray(newSongs) || newSongs.length === 0) return;
    
    setSongs(prevSongs => {
      // Only update if we have new songs
      if (newSongs.length > prevSongs.length) {
        // If we have enough songs, transition to results
        if (newSongs.length >= MIN_SONGS_REQUIRED && !hasReceivedSongsRef.current) {
          hasReceivedSongsRef.current = true;
          if (videoTimeoutRef.current) {
            clearTimeout(videoTimeoutRef.current);
          }
          setTimeout(() => {
            setCurrentPage('results');
          }, 0);
        }
        return newSongs;
      }
      return prevSongs;
    });
  };

  // If not authenticated, show password page
  if (!isAuthenticated) {
    return (
      <div className="app-container">
        <PasswordPage onPasswordSuccess={() => setIsAuthenticated(true)} />
      </div>
    );
  }

  return (
    <div
      ref={containerRef}
      className={`App ${isTransitioning ? 'transitioning' : ''}`}
      style={{
        backgroundImage: `url(${backgroundImage?.src})`,
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        backgroundRepeat: 'no-repeat',
      }}
    >
      {currentPage === 'cassette' && (
        <>
          <CassettePage
            onSubmit={startVideoTransition}
            isTransitioning={isTransitioning}
            onSongsUpdate={handleSongsUpdate}
            isError={isError}
            setIsError={setIsError}
          />
          {isError && (
            <div className="error-message">
              No songs found. Please try again.
            </div>
          )}
        </>
      )}
      
      {currentPage === 'video' && (
        <div className="video-container" style={{ position: 'relative', width: '100%', height: '100%' }}>
          <video
            ref={videoRef}
            src="/background_video.mp4"
            autoPlay
            onEnded={() => {
              // Only transition if we haven't received enough songs
              if (currentPage === 'video' && !hasReceivedSongsRef.current) {
                handleError();
              }
            }}
            onError={() => handleError()}
            style={{
              position: 'absolute',
              top: 0,
              left: 0,
              width: '100%',
              height: '100%',
              objectFit: 'cover'
            }}
          />
          <div className="vhs-overlay"></div>
          <div className="noise"></div>
        </div>
      )}
      
      {currentPage === 'results' && (
        <ResultsPage
          songs={songs}
          onBack={handleBack}
        />
      )}
      
      {(currentPage === 'cassette' || currentPage === 'results') && (
        <>
          <div className="vhs-overlay"></div>
          <div className="noise"></div>
        </>
      )}
    </div>
  );
}

export default App;
