import React, { useState, useRef, useEffect } from 'react';
import './CassettePage.css';
import YearWheel from './YearWheel';
import { useResponsiveLayout } from './useResponsiveLayout';

const BACKEND = "http://54.165.219.229:8001"

const CassettePage = ({ onSubmit, isTransitioning, onSongsUpdate }) => {
  const [selectedSong, setSelectedSong] = useState(null);
  const [searchTerm, setSearchTerm] = useState('');
  const [searchResults, setSearchResults] = useState([]);
  const [startYear, setStartYear] = useState(1950);
  const [endYear, setEndYear] = useState(2024);
  const [selectedFile, setSelectedFile] = useState(null);
  const [selectedFilter, setSelectedFilter] = useState({ id: 'popular', streamThreshold: 1000000 });
  const searchTimeoutRef = useRef(null);
  const searchContainerRef = useRef(null);
  const fileInputRef = useRef(null);

  const { interpolatePosition } = useResponsiveLayout(0.5, 2.5);

  const positions = {
    searchContainer: {
      portrait: { x: 48, y: 22, scale: 2.2 },
      natural: { x: 49.5, y: 22, scale: 0.76 },
      landscape: { x: 49.5, y: 10, scale: 1 }
    },
    fileUpload: {
      portrait: { x: 50, y: 38, scale: 1 },
      natural: { x: 50, y: 43, scale: 1 },
      landscape: { x: 50, y: 48, scale: 1 }
    },
    submitButton: {
      portrait: { x: 50, y: 60, scale: 1 },
      natural: { x: 49.5, y: 60, scale: 1 },
      landscape: { x: 50, y: 62, scale: 1 }
    },
    yearWheelLeft: {
      portrait: { x: -9, y: 40, scale: 6.5 },
      natural: { x: 30, y: 40, scale: 2 },
      landscape: { x: 30, y: 36, scale: 2.5 }
    },
    yearWheelRight: {
      portrait: { x: 108, y: 40, scale: 6.5 },
      natural: { x: 69, y: 40, scale: 2 },
      landscape: { x: 69, y: 36, scale: 2.5 }
    }
  };

  const searchPos = interpolatePosition(
    positions.searchContainer.portrait,
    positions.searchContainer.natural,
    positions.searchContainer.landscape
  );

  const filePos = interpolatePosition(
    positions.fileUpload.portrait,
    positions.fileUpload.natural,
    positions.fileUpload.landscape
  );

  const submitPos = interpolatePosition(
    positions.submitButton.portrait,
    positions.submitButton.natural,
    positions.submitButton.landscape
  );

  const leftWheelPos = interpolatePosition(
    positions.yearWheelLeft.portrait,
    positions.yearWheelLeft.natural,
    positions.yearWheelLeft.landscape
  );

  const rightWheelPos = interpolatePosition(
    positions.yearWheelRight.portrait,
    positions.yearWheelRight.natural,
    positions.yearWheelRight.landscape
  );

  const handleSearchChange = async (e) => {
    const value = e.target.value;
    setSearchTerm(value);

    if (searchTimeoutRef.current) {
      clearTimeout(searchTimeoutRef.current);
    }

      if (value.trim()) {
        searchTimeoutRef.current = setTimeout(async () => {
          try {
            const response = await fetch(`${BACKEND}/search`, {
              method: 'POST',
              headers: {
                'Content-Type': 'application/json',
              },
              body: JSON.stringify({
                query: value,
                limit: 25
              })
            });

            if (!response.ok) {
              throw new Error(await response.text());
            }

            const data = await response.json();
            setSearchResults(data);
          } catch (err) {
            console.error('Search failed:', err);
          }
        }, 50);
      } else {
        setSearchResults([]);
      }
  };

  const handleTrackSelect = (song) => {
    setSelectedSong(song);
    setSearchTerm(song.title);
    setSearchResults([]);
    setSelectedFile(null);
  };

  const handleFileSelect = (event) => {
    const file = event.target.files[0];
    if (file) {
      setSelectedFile(file);
      setSelectedSong(null);
      setSearchTerm('');
      setSearchResults([]);
    }
  };

    const handleSubmit = () => {
      if (selectedFile) {
        onSubmit(); // Trigger video transition

        const formData = new FormData();
        formData.append('audio_file', selectedFile);
        formData.append('start_year', startYear);
        formData.append('end_year', endYear);
        formData.append('stream_threshold', selectedFilter.streamThreshold);

        fetch(`${BACKEND}/upload`, {
          method: 'POST',
          body: formData
        })
        .then(response => {
          if (!response.ok) {
            throw new Error('Network response was not ok');
          }
          return response.json();
        })
        .then(data => {
          onSongsUpdate(data);
        })
        .catch(error => {
          console.error('Failed to upload file:', error);
        });
      } else if (selectedSong) {
        onSubmit(); // Trigger video transition

        const fetchSimilarSongs = async () => {
          try {
            const response = await fetch(`${BACKEND}/similar`, {
              method: 'POST',
              headers: { 'Content-Type': 'application/json' },
              body: JSON.stringify({
                song_id: selectedSong.id,
                isrc: selectedSong.isrc,
                start_year: startYear,
                end_year: endYear,
              })
            });

            if (!response.ok) {
              throw new Error('Network response was not ok');
            }

            const reader = response.body.getReader();
            const decoder = new TextDecoder();
            const songs = [];

            while (true) {
              const { done, value } = await reader.read();
              if (done) break;

              const chunk = decoder.decode(value);
              const lines = chunk.split('\n').filter(Boolean);

              for (const line of lines) {
                const song = JSON.parse(line);
                songs.push(song);
                onSongsUpdate([...songs]);
              }
            }
          } catch (error) {
            console.error('Failed to fetch similar songs:', error);
          }
        };

        fetchSimilarSongs();
      }
    };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (searchContainerRef.current && !searchContainerRef.current.contains(event.target)) {
        setSearchResults([]);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => document.removeEventListener('mousedown', handleClickOutside);
  }, []);

  return (
    <div className={`cassette-page ${isTransitioning ? 'fade-out' : ''}`}>
      <YearWheel
        position="left"
        onChange={setStartYear}
        startYear={1950}
        endYear={2024}
        initialYear={1950}
        style={{
          position: 'absolute',
          left: `${leftWheelPos.x}%`,
          top: `${leftWheelPos.y}%`,
          transform: `translate(-50%, -50%) scale(${leftWheelPos.scale})`,
          transformOrigin: 'center center'
        }}
      />
      
      <div
        ref={searchContainerRef}
        className="search-container"
        style={{
          position: 'absolute',
          left: `${searchPos.x}%`,
          top: `${searchPos.y}%`,
          transform: `translate(-50%, -50%)`,
          width: `${searchPos.scale * 1000}px`
        }}
      >
        <div className="search-wrapper">
          {selectedSong && (
            <div className="selected-song-artwork">
              <img
                src={selectedSong.artwork_url}
                alt={selectedSong.title}
                className="artwork-preview"
              />
            </div>
          )}
          <input
            type="text"
            value={searchTerm}
            onChange={handleSearchChange}
            placeholder="Search for a song..."
            className="search-input"
          />
        </div>
        {searchResults.length > 0 && (
          <div className="search-results">
            {searchResults.map((song) => (
              <div
                key={song.id}
                className="search-result-item"
                onClick={() => handleTrackSelect(song)}
              >
                <img
                  src={song.artwork_url}
                  alt={song.title}
                  className="search-result-artwork"
                />
                <div className="search-result-info">
                  <div className="search-result-title">{song.title}</div>
                  <div className="search-result-artist">{song.artist}</div>
                </div>
              </div>
            ))}
          </div>
        )}
      </div>

      <div
        className="file-upload-container"
        style={{
          position: 'absolute',
          left: `${filePos.x}%`,
          top: `${filePos.y}%`,
          transform: 'translate(-50%, -50%)'
        }}
      >
        <input
          type="file"
          ref={fileInputRef}
          onChange={handleFileSelect}
          accept="audio/*"
          className="file-input"
          id="audio-file-input"
        />
      </div>

      <YearWheel
        position="right"
        onChange={setEndYear}
        startYear={1950}
        endYear={2024}
        initialYear={2024}
        style={{
          position: 'absolute',
          left: `${rightWheelPos.x}%`,
          top: `${rightWheelPos.y}%`,
          transform: `translate(-50%, -50%) scale(${rightWheelPos.scale})`,
          transformOrigin: 'center center'
        }}
      />

      <button
        onClick={handleSubmit}
        className="submit-button"
        disabled={!selectedSong && !selectedFile}
        style={{
          position: 'absolute',
          left: `${submitPos.x}%`,
          top: `${submitPos.y}%`,
          transform: 'translate(-50%, -50%)'
        }}
      >
        Submit
      </button>
    </div>
  );
};

export default CassettePage;
